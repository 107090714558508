@import './breakpoints';
@import './colors';
@import './fonts';

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $default-font;
  background: $empty-white;
  color: $pure-black;
}

.overflow_hidden {
  overflow: hidden;
}

main {
  flex: 1 0 auto;
  padding-top: 160px;

  @mixin sm-md {
    padding-top: 96px;
  }

  @include sm {
    @include sm-md;
  }
  @include md {
    @include sm-md;
  }
}

@import './imports';
