.industries__content {
  transition: max-height ease-in-out 1s;

  b.title-text {
    display: block;
    padding-bottom: 4px;
  }

  @include sm {
    overflow: hidden;
    max-height: 0;
  }

  &_open {
    max-height: 200vh;
  }
}
