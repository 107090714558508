.container {
  max-width: calc(1600px + 24px * 2);
  width: calc(100% / 12 * 10 + 24px * 2);
  margin: 0 auto;
  padding: 0 24px;

  @include sm {
    width: 100%;
  }

  &_wide {
    max-width: calc(1920px - 24px * 2);
    width: calc(100% - 24px * 2);
  }
}
