.button {
  @extend .typography_button-label;
  cursor: pointer;
  border: none;
  background: $purple-amethyst;
  color: $empty-white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 18px 16px;
  width: 224px;
  height: 56px;

  @include sm {
    width: 100%;
  }
}
