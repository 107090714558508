.tabs__main-text {
  @extend .typography_content-highlight;
  margin-bottom: 64px;

  &:only-child {
    margin-bottom: 0;
  }

  @mixin xl-xxl {
    width: calc(100% / 10 * 7);
  }
  @include xxl {
    @include xl-xxl;
  }
  @include xl {
    @include xl-xxl;
  }
  @include md {
    margin-bottom: 32px;
  }
  @include sm {
    margin-bottom: 24px;
  }
}
