.contact__title {
  @extend .typography_h3;
  text-align: center;
  margin-bottom: 64px;

  @include md {
    margin-bottom: 48px;
  }
  @include sm {
    margin-bottom: 40px;
  }
}
