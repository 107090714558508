.media-kit__item {
  width: 25%;
  transition: transform ease-in-out 0.5s;
  position: relative;

  &:hover {
    z-index: 5 !important;
    transform: scale(1.1);
  }

  @include sm {
    width: 100%;
  }

  &:nth-child(odd) {
    .media-kit__title {
      color: $cold-black;
    }
  }
  &:nth-child(even) {
    color: $empty-white;
  }
}
