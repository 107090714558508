/* src/assets/styles/utils/_colors.scss  */
$blue-l3: #39a4dd;
$blue-l1: #f8fbff;
$blue-l2: #d2dfe8;
$blue-l2-hover: #63c4f8;
$blue-l4: #b9e7ff;
$blue-l5: #6cbbe4;
$blue-l6: #1eb5fc;
$blue-d1: #0d4f6f;
$blue-d2: #34495e;
$blue-d3: #4c5f73;
$gray-l1: #7f868e;
$gray-l2: #868e92;
$gray-l3: #dddddd;
$gray-d1: #2d292a;
$yellow-l1: #fff5ba;
$orange-l1: #f17f67;
$orange-l2: #ff8269;
$red-l1: #ec4847;
$red-l1-hover: #ff8269;
$red-l1-active: #fc5f45;
$green-l1: #67d149;
$green-l1-hover: #76e657;
$green-l1-active: #58dc33;
$white: #fff;

$purple-accent-1: #7092fe;
$purple-accent-2: #8aa6ff;
$purple-accent-3: #d0d8ff;
$blue-accent-3: #ecf1f6;
$divader-grey: #f0f0f0;
