/* src/assets/styles/base/_button.scss  */
@mixin transition {
  transition: background-color 0.3s ease, color 0.2s ease;
}

.clean-button {
  border: none;
  background: none;
}

.button {
  @extend .clean-button;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  color: white;
  padding: 14px 40px;
}

.base-32-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  // font-family: $ageo;
  text-transform: capitalize;
  padding: 5px 12px 3px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.base-40-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  // font-family: $ageo;
  text-transform: uppercase;
  padding: 9px 12px 7px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  @include mobile {
    font-size: 12px;
    letter-spacing: 0.1px;
  }
}

.base-56-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  // font-family: $ageo;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  @include mobile {
    min-height: 40px;
    font-size: 12px;
    font-weight: 500;
    padding: 0 24px;
  }
}

.base-64-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  // font-family: $ageo;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  @include mobile {
    min-height: 40px;
    font-size: 12px;
  }
}

.base-48-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 48px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  // font-family: $ageo;
  text-transform: uppercase;
  padding: 13px 16px 11px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.base-56-aux-btn {
  display: inline-flex;
  justify-content: center;
  align-content: center;
  min-height: 56px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  // font-family: $ageo;
  text-transform: capitalize;
  padding: 17px 12px 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  @include mobile {
    min-height: 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    padding: 9px 12px 7px;
  }
}

.red-btn {
  transition: background-color 0.3s ease;

  &,
  &:focus,
  &:visited {
    background-color: $red-l1;
    color: $white;
  }

  &:hover {
    background-color: $red-l1-hover;
    color: $white;
  }

  &:active {
    background-color: $red-l1-active;
    color: $white;
  }
}

.green-btn {
  transition: background-color 0.3s ease;

  &,
  &:focus,
  &:visited {
    background-color: $green-l1;
    color: $white;
  }

  &:hover {
    background-color: $green-l1-hover;
    color: $white;
  }

  &:active {
    background-color: $green-l1-active;
    color: $white;
  }
}

.light-gray-btn {
  @include transition;

  &,
  &:focus,
  &:visited {
    background-color: $white;
    color: $gray-d1;
  }

  &:hover {
    background-color: $blue-l4;
    color: $gray-d1;
  }

  &:active {
    background-color: $blue-l5;
    color: $white;
  }
}

.blue-btn {
  @include transition;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: $blue-l6;
    color: $white;
  }

  &:hover {
    background-color: $blue-l2-hover;
    color: $white;
  }
}

.blue-l3-btn {
  @include transition;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: $blue-l3;
    color: $white;
  }

  &:hover {
    background-color: $blue-l2-hover;
    color: $white;
  }
}

.white-red-btn {
  @include transition;

  &,
  &:focus,
  &:visited {
    background-color: $white;
    color: $red-l1;
  }

  &:hover {
    background-color: $white;
    color: $red-l1-hover;
  }

  &:active {
    background-color: $white;
    color: $red-l1-active;
  }
}

.purple-btn {
  @include transition;

  &,
  &:focus,
  &:visited {
    background-color: $purple-accent-1;
    color: $white;
  }

  &:hover {
    background-color: $purple-accent-2;
  }

  &:active {
    background-color: $purple-accent-2;
  }
}

.light-blue-btn {
  @include transition;

  &,
  &:focus,
  &:visited {
    background-color: $blue-accent-3;
    color: $gray-d1;
  }

  &:hover {
    color: $purple-accent-1;
  }

  &:active {
    color: $purple-accent-1;
  }
}
