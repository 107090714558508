.header__nav-link {
  @extend .typography_header-nav, .typography_reset-link;
  color: $cold-black;
  margin: 0 28px;

  @include lg {
    margin: 0 24px;
  }
  @include md {
    margin: 0;
    padding: 24px;
  }
  @include sm {
    margin: 0;
    padding: 24px;
  }

  &_margin_right-0 {
    margin-right: 0;
  }

  &_separated {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -28px;
      height: 24px;
      width: 1px;
      background: $cold-black25;
      opacity: 0.5;

      @include lg {
        left: -24px;
      }
      @mixin sm-md {
        left: unset;
        right: 0;
      }
      @include md {
        @include sm-md;
      }
      @include sm {
        @include sm-md;
      }
    }
  }

  &_desktop-hide {
    display: none;

    @include md {
      display: block;
    }
    @include sm {
      display: block;
    }
  }
}
