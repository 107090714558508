.container-aux {
  width: 100%;
  min-width: 320px;
  // max-width: calc(1680px + 48px);
  max-width: 1200px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;

  @include mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.base-info-block {
  @include isFlex($align: flex-start, $justify: flex-start, $direction: column);
  width: 100%;
  max-width: 100%;
  background-color: $blue-l1;
  padding: 100px 0 92px;

  @include mobile {
    padding: 48px 0 20px;
  }
}

.base-info-wrapper {
  @include isFlex($align: flex-start, $justify: flex-start, $direction: column);
  width: 100%;
  max-width: 720px;
}

.base-info-block-title {
  font-size: 64px;
  line-height: 76px;
  font-weight: 500;
  letter-spacing: 0;
  // // font-family: $ageo;
  text-align: center;
  color: $blue-l2;
  margin: 0 0 40px;

  @include mobile {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 8px;
  }
}

.base-info-list-wrapper {
  @include isFlex($align: flex-start, $justify: flex-start, $direction: column);
  width: 100%;
  max-width: 100%;

  .list-base-item-title {
    color: $gray-d1;
  }

  .list-base-item-description {
    color: $gray-l1;
  }

  .list-base-item {
    ul li::before {
      background-color: $gray-l1;
    }

    ol li::before {
      color: $gray-l1;
    }
  }
}
