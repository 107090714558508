.features {
  margin-left: -32px;
  margin-right: -32px;
  display: flex;
  overflow-y: auto;
  margin-top: 40px;

  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  scroll-snap-type: x mandatory; /* Chrome Canary */
  // scroll-snap-type: y mandatory; /* Chrome Canary */
  scroll-snap-type: mandatory; /* Firefox */
  -ms-scroll-snap-type: mandatory; /* IE/Edge */
  -webkit-scroll-snap-type: mandatory; /* Safari */
  -webkit-scroll-snap-destination: 0% 0%;
  -webkit-overflow-scrolling: touch; /* important for iOS */

  @include md {
    margin-top: 32px;
  }
  @include sm {
    margin-top: 24px;
    margin-left: -24px;
    margin-right: -24px;
  }
}
