.industries__item {
  background: $bg10;
  padding: 32px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @mixin sm-md {
    padding: 24px;
    margin-bottom: 16px;
  }
  @include md {
    @include sm-md;
  }
  @include md {
    @include sm-md;
  }
}
