/* src/assets/styles/base/_link.scss  */

.link {
  // font-family: $ageo;
  font-size: 18px;
  font-weight: 700;
}

.light-gray-link {
  transition: color 0.2s ease;
  outline: none;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: transparent;
    color: $gray-l2;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    color: $gray-d1;
    outline: none;
  }
}

.gray-d1-link {
  transition: color 0.2s ease;
  outline: none;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: transparent;
    color: $gray-d1;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    color: $gray-l2;
    outline: none;
  }
}

.gray-l2-link {
  transition: color 0.2s ease;
  outline: none;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: transparent;
    color: $gray-l2;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    color: $gray-l1;
    outline: none;
  }
}

.img-link {
  transition: opacity 0.2s ease-in-out;

  &,
  &:focus,
  &:active,
  &:visited {
    opacity: 1;
  }

  &:hover {
    opacity: 0.8;
  }
}

.gray-red-link {
  transition: color 0.2s ease;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: transparent;
    color: $gray-d1;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    color: $red-l1-hover;
    outline: none;
  }
}

.red-link {
  transition: color 0.2s ease;
  text-decoration: underline;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: transparent;
    color: $red-l1;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    color: $red-l1-hover;
    outline: none;
  }
}

.purple-link {
  transition: color 0.2s ease;
  text-decoration: none;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: transparent;
    color: $purple-accent-1;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    color: $purple-accent-1;
    outline: none;
  }
}

.white-red-link {
  transition: color 0.2s ease-in-out;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: transparent;
    color: $white;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    color: $red-l1;
    outline: none;
  }
}

.orange-link {
  transition: color 0.2s ease-in-out;

  &,
  &:focus,
  &:active,
  &:visited {
    background-color: transparent;
    color: $orange-l2;
    outline: none;
  }

  &:hover {
    background-color: transparent;
    color: $orange-l1;
    outline: none;
  }
}
