/*
@include xxl {
  // >1920
}
@include xl {
  // 1440 - 1919
}
@include lg {
  // 1024 - 1439
}
@include md {
  // 768 - 1023
}
@include sm {
  // <767px
}
*/

/*
sm  -  < 767
md  -    768 - 1023
lg  -    1024 - 1439
xl  -    1440 - 1919
xxl -  > 1920
*/

@mixin xxl {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1440px) and (max-width: 1919px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) and (max-width: 1439px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}
