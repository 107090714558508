/* src/assets/styles/utils/_mixins.scss  */

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin isFlex($align: center, $justify: center, $direction: row, $wrap: wrap) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $wrap;
}
