.contact__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @include sm {
    margin-bottom: 16px;
  }
}
