.contact__form {
  position: relative;
  padding: 0 calc(100% / 10 * 2);

  @include md {
    padding: 0 calc(100% / 10);
  }
  @include sm {
    padding: 0;
  }
}
