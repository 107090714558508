.typography {
  margin: 0;
  padding: 0;
}

.typography_reset-link {
  text-decoration: none;
  color: inherit;
}

.typography_header-nav {
  font-size: 16px;
  line-height: 24px;
}

.typography_footer-nav {
  font-size: 14px;
  line-height: 24px;
}

.typography_heading {
  @extend .typography;
  font-family: $heading-font;
  color: $cold-black;
  font-weight: 400;
}

.typography_h1 {
  @extend .typography_heading;
  font-weight: 300;
  font-size: 80px;
  line-height: 1.1;
  letter-spacing: -3px;

  @include lg {
    font-size: 64px;
    line-height: 1.37;
  }
  @include md {
    font-size: 52px;
    line-height: 1.23;
  }
  @include sm {
    font-size: 40px;
    line-height: 1.15;
  }
}

.typography_h2 {
  @extend .typography_heading;
  font-size: 40px;
  line-height: 1.02;
  letter-spacing: -2px;
  margin-bottom: 24px;
}

.typography_h3 {
  @extend .typography_heading;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -2px;
}

.typography_h4 {
  @extend .typography_heading;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -2px;

  @include md {
    font-size: 22px;
    line-height: 1.2;
  }
  @include sm {
    font-size: 15px;
    line-height: 1.26;
    letter-spacing: 0;
  }
}

.typography_h6 {
  @extend .typography;
  font-family: $default-font;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 700;

  @include md {
    font-size: 18px;
    line-height: 1.67;
  }
  @include sm {
    font-size: 16px;
    line-height: 1.62;
  }
}

.typography_subtitle {
  font-family: $default-font;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.typography_content {
  font-family: $default-font;
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 16px 0;

  @include lg {
    font-size: 15px;
    line-height: 1.6;
  }
  @include md {
    font-size: 14px;
    line-height: 1.57;
  }
  @include sm {
    font-size: 13px;
    line-height: 1.54;
  }
}

.typography_content-highlight {
  font-family: $default-font;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 400;
  margin: 0;

  @include lg {
    font-size: 18px;
    line-height: 1.56;
  }
  @include md {
    font-size: 16px;
    line-height: 1.5;
  }
  @include sm {
    font-size: 14px;
    line-height: 1.43;
  }
}

.typography_button-label {
  font-family: $default-font;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $cold-black;
}
