.contact__select {
  @include contact__default-input;

  option {
    display: none;
  }

  height: 76px;

  @mixin sm-md {
    height: 72px;
  }
  @include md {
    @include sm-md;
  }
  @include sm {
    @include sm-md;
  }
}

.select {
  @include contact__default-input;
  position: relative;
  margin-bottom: 24px;

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.open {
    @include active-state;
    color: $pure-black;
  }

  &.disabled {
    .select__inner,
    .select__input {
      cursor: not-allowed;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .select__item {
      cursor: not-allowed;
    }
  }

  [hidden] {
    display: none !important;
  }
}

.select__inner {
  cursor: text;
}

.select__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  background-image: url('../images/icons/close.svg');
  background-size: 8px;
  width: 8px;
  line-height: 1;

  &:focus {
    outline: none;
  }
}

.select__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow: hidden;
}

.select__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.select__list_multiple {
  display: inline;

  .select__item {
    display: inline-block;
    vertical-align: middle;
    word-break: break-all;
    box-sizing: border-box;
    margin-right: 16px;
  }
}

.select__list_dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  left: -1px;
  width: calc(100% + 2px);
  background-color: $empty-white;
  border: 1px solid $purple-amethyst;
  border-top: none;
  top: 100%;
  margin-top: -1px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;

  &.active {
    visibility: visible;
  }

  .select__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
  }

  .select__item {
    position: relative;
    padding: 16px;

    &_selectable.highlighted {
      color: $cobalt-blue;
    }
  }
}

.flipped .select__list_dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
}

.select__item {
  cursor: default;

  &_selectable {
    cursor: pointer;
  }

  &_disabled {
    cursor: not-allowed;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.5;
  }
}

.select__input {
  display: inline-block;
  vertical-align: baseline;
  border: 0;
  max-width: 100%;

  &:focus {
    outline: 0;
  }
}
