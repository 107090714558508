.footer {
  flex-shrink: 0;
  padding: 40px 0;

  @mixin sm-md {
    padding: 32px 0;
  }
  @include md {
    @include sm-md;
  }
  @include sm {
    @include sm-md;
  }
}
