.about__quote {
  @extend .typography_h3;
  color: $purple-amethyst;
  padding: 0;
  margin: 0 calc(100% / 10) 64px calc(100% / 10);

  @include xxl {
    margin: 0 calc(100% / 10 * 2) 64px calc(100% / 10);
    max-width: 1000px;
  }
  @include xl {
    max-width: 850px;
  }
  @include lg {
    font-size: 28px;
    max-width: 666px;
    margin-bottom: 56px;
  }
  @include md {
    font-size: 22px;
    margin-bottom: 40px;
  }
  @include sm {
    font-size: 16px;
    margin: 0 24px 24px 24px;
    letter-spacing: 0;
  }
}
