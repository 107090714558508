.contact__input-wrapper {
  position: relative;
  background: $empty-white;
  line-height: 0;
  width: calc(50% - 12px);

  @include sm {
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin: 0;
    }
  }

  &_width-100 {
    width: 100%;
  }
}
