.accordion__content {
  overflow: hidden;
  max-height: 0;
  transition: max-height ease-in-out 0.3s;

  &_open {
    max-height: 200vh;
  }

  &-wrapper {
    padding: 16px 32px 56px 32px;

    @include md {
      padding: 0 32px 32px 32px;
    }

    @include sm {
      padding: 0 24px 24px 24px;
    }
  }
}
