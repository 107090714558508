.about__subtitle {
  @extend .typography_content;
  max-width: 588px;
  margin-bottom: 48px;

  @include md {
    margin-bottom: 32px;
  }
  @include sm {
    margin-bottom: 24px;
  }
}
