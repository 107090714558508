.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;

  @mixin md-lg {
    margin-top: 24px;
  }
  @include lg {
    @include md-lg;
  }
  @include md {
    @include md-lg;
  }
  @include sm {
    margin-top: 16px;
  }
}
