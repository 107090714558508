.media-kit__title {
  @extend .typography_subtitle;
  width: 100%;
  margin: 0;
  text-align: center;
  padding-bottom: 32px;
  position: absolute;
  bottom: 0;

  @include md {
    padding-bottom: 24px;
  }
  @include sm {
    padding-bottom: 24px;
  }
}
