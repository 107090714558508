.contact__input {
  @include contact__default-input;
  height: 76px;

  @mixin sm-md {
    height: 72px;
  }
  @include md {
    @include sm-md;
  }
  @include sm {
    @include sm-md;
  }
}
