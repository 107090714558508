.accordion__text-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 88px;

  @include lg {
    padding: 0 56px;
  }
  @include md {
    padding: 0 32px;
  }
  @include sm {
    padding: 0;
  }
}
