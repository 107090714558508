.features__subtitle {
  @extend .typography_content;
  line-height: 1.6;

  @include lg {
    font-size: 15px;
    line-height: 1.71;
  }
  @include md {
    line-height: 1.69;
  }
  @include sm {
    display: none;
  }
}
