.accordion__item {
  overflow: hidden;
  background: $bg10;
  border: solid $border10;
  border-width: 1px 0;
  position: relative;

  &:first-child {
    border-top-width: 2px;
  }
  &:last-child {
    border-bottom-width: 2px;
  }

  &_open {
    border-color: transparent;
  }
}
