.blob {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;

  canvas {
    z-index: -2;
    width: 100vh;
    height: 100vh;
    opacity: 1;
    transition: ease-in-out 5s;
  }

  &_opacity_0 {
    canvas {
      opacity: 0 !important;
    }
  }
}
