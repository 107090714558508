/* src/assets/styles/base/_stamps.scss  */

.gray-l2-stamp {
  background-color: $gray-l2;
  color: $white;
}

.base-30-stamp {
  min-height: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  // font-family: $ageo;
  text-transform: uppercase;
  padding: 7px 8px 5px;
  border-radius: 4px;

  @include mobile {
    min-height: 24px;
    font-size: 12px;
    line-height: 14px;
  }
}
