/* src/assets/styles/base/_breakpoints.scss  */

/*
@include mobile {}
@include tablet {}
@include desktop {}
*/

@mixin mobile {
  @media (max-width: 860px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 861px) and (max-width: 1188px) {
    @content;
  }
}

@mixin tablet-footer {
  @media (min-width: 861px) and (max-width: 900px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1189px) {
    @content;
  }
}
