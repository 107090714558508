@font-face {
  font-family: 'Fractul';
  src: url('../fonts/fractul/fractul-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fractul';
  src: url('../fonts/fractul/fractul-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Epilogue';
  src: url('../fonts/epilogue/epilogue.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Epilogue';
  src: url('../fonts/epilogue/epilogue.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

$default-font: 'Epilogue', 'Lucida Grande', Helvetica, sans-serif;
$heading-font: 'Fractul', Verdana, Geneva, sans-serif;
