.header__menu {
  display: none;
  height: 24px;
  width: 24px;
  cursor: pointer;

  @include md {
    display: block;
  }
  @include sm {
    display: block;
  }
}
