.footer__copyright {
  @extend .typography_footer-nav;
  color: $middle-grey;

  @include sm {
    margin-bottom: 24px;
  }
  @media (max-width: 460px) {
    text-align: center;
  }
}
