$pure-black: #000000;
$dark-grey: #5a6688;
$middle-grey: #9fa7b9;
$light-grey: #e9ebf5;
$cobalt-blue: #344bf3;
$cold-black: #0c1838;
$cold-black50: #868c9d;
$cold-black25: #c3c7cf;
$purple-amethyst: #6e93fd;
$purple-amethyst50: #b7c9ff;
$purple-amethyst25: #dbe5ff;
$empty-white: #ffffff;

$border10: #f5f5f5;

$bg10: #f6f8ff;
