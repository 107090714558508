.platform__text-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 56px;

  @include md {
    margin-bottom: 48px;
  }
  @include sm {
    margin-bottom: 24px;
  }
}
