.header {
  flex-shrink: 0;
  height: 160px;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  background: $empty-white;
  display: flex;
  align-items: center;
  transition: height ease-in-out 0.2s, box-shadow ease-in-out 0.3s,
    background ease-in-out 0.3s;

  @mixin sm-md {
    height: 96px;
  }
  @include md {
    @include sm-md;
  }
  @include sm {
    @include sm-md;
  }
}

.header_scrolled {
  height: 96px;
  box-shadow: 0px 8px 16px rgba(162, 162, 162, 0.07);
  background: rgba($empty-white, 0.95);
}
