.header__close-menu-wrapper {
  display: none;
  height: 96px;
  align-items: center;
  justify-content: flex-end;

  @mixin sm-md {
    display: flex;
  }
  @include md {
    @include sm-md;
    justify-content: flex-end;
  }
  @include sm {
    @include sm-md;
    justify-content: space-between;
  }
}
