.contact__message-container {
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.contact__error-text {
  @extend .typography_subtitle;
  font-weight: 400;
  text-align: center;
  color: crimson;
}

.contact__success-text {
  @extend .typography_subtitle;
  font-weight: 400;
  text-align: center;
  color: $cobalt-blue;
  font-weight: bold;
}
