.header__close-menu {
  display: none;
  cursor: pointer;
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  padding: 8px;

  @include md {
    display: block;
  }
  @include sm {
    display: block;
  }
}
