.accordion__text {
  @extend .typography_content;
  margin: 0 0 32px 0;

  &:last-child {
    margin-bottom: 0;
  }

  @include sm {
    margin: 0 0 24px 0;
  }

  b {
    display: block;
    margin-bottom: 8px;
  }

  li {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
