.footer__nav-item {
  display: inline-flex;

  @mixin xl-xxl {
    margin: 0 12px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @include xxl {
    @include xl-xxl;
  }
  @include xl {
    @include xl-xxl;
  }
  @mixin md-lg {
    width: 50%;
    margin-top: 24px;

    &:nth-child(-n + 2) {
      margin-top: 0;
    }

    &_lg-33 {
      width: 33.33%;

      &:nth-child(-n + 3) {
        margin-top: 0;
      }
    }
  }
  @include lg {
    @include md-lg;
  }
  @include md {
    @include md-lg;
  }
  @include sm {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
