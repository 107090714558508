@mixin active-state {
  color: $cold-black50;
  border: 1px solid $purple-amethyst;
}

@mixin contact__default-input {
  @extend .typography_content;
  outline: none;
  appearance: none;
  color: $cold-black25;
  width: 100%;
  border: 1px solid $cold-black25;
  padding: 36px 16px 16px 16px;
  transition: color ease-in-out 0.3s, border ease-in-out 0.3s;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(:placeholder-shown) {
    color: $pure-black;
  }

  &:hover,
  &:focus,
  &:active {
    @include active-state;

    &:not(:placeholder-shown) {
      color: $pure-black;
    }
  }
}
