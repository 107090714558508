.tabs__content {
  background: $bg10;
  width: 100%;
  order: 1;
  display: none;
  padding: 56px;

  @include md {
    padding: 48px;
  }
  @include sm {
    padding: 24px;
  }

  .tabs__input:checked + .tabs__label + & {
    display: block;
  }
}
