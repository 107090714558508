.footer__copyright-socials {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    align-items: unset;
  }
}
