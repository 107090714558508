.media-kit__link {
  @extend .typography_reset-link;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  outline: none;
}
