.main-banner__text {
  @extend .typography_content-highlight;
  text-align: center;
  color: $dark-grey;
  margin-bottom: 40px;

  span {
    display: block;
  }

  @include sm {
    margin-bottom: 24px;

    span {
      display: inline;
    }
  }
}
