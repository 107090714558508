.footer__logo {
  margin-right: 24px;

  @include xxl {
    margin-right: 160px;
  }
  @include xl {
    margin-right: 64px;
  }
  @mixin md-lg {
    display: flex;
    align-items: center;
    margin-right: 48px;
  }
  @include lg {
    @include md-lg;
  }
  @include md {
    @include md-lg;
  }
  @mixin sm-990-768 {
    margin-bottom: 24px;
    margin-right: 0;
  }
  @media (max-width: 990px) and (min-width: 768px) {
    margin-right: 0;
    @include sm-990-768;
  }
  @include sm {
    @include sm-990-768;
  }
  @media (max-width: 460px) {
    margin-right: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }
}
