.industries__icon {
  width: 64px;
  height: 64px;
  margin-bottom: 16px;

  @include md {
    width: 48px;
    height: 48px;
  }
  @include sm {
    width: 40px;
    height: 40px;
  }
}
