.header__menu-overlay {
  display: none;
  position: absolute;
  top: 0;
  background: $empty-white;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  transform: translateX(100%);

  @include md {
    display: block;
  }
  @include sm {
    display: block;
  }
}

.header__menu-overlay_transition {
  transition: opacity ease-in-out 0.3s;
}

.header__menu-overlay_show {
  transform: translateX(0%);
  opacity: 0.6;
}
