.header__nav-links {
  display: flex;

  @mixin sm-md {
    flex-direction: column;
  }

  @include md {
    @include sm-md;
  }
  @include sm {
    @include sm-md;
  }
}
