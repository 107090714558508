.header__nav {
  display: flex;
  align-items: center;
  transform: none;

  @mixin sm-md {
    align-items: unset;
    transform: translateX(100%);
    background: $empty-white;
    flex-direction: column;
    position: fixed;
    z-index: 8;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 64px 56px 64px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    overflow-y: auto;
  }
  @include md {
    @include sm-md;
    width: 40%;
    min-width: 330px;
  }
  @include sm {
    @include sm-md;
    width: 100%;
  }
}

.header__nav_transition {
  transition: transform ease-in-out 0.3s;
}

.header__nav_show {
  @mixin sm-md {
    transform: translateY(0%);
  }
  @include md {
    @include sm-md;
  }
  @include sm {
    @include sm-md;
  }
}
