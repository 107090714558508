.about__column {
  width: calc(50% - 12px);

  @include sm {
    width: 100%;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
