.tabs__label {
  @extend .typography_button-label;
  background: $empty-white;
  width: 25%;
  cursor: pointer;
  border: solid $light-grey;
  border-width: 0 1.4px 2.8px 1.4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    border-width: 0 1.4px 2.8px 0;
  }
  &:last-of-type {
    border-width: 0 0 2.8px 1px;
  }

  @mixin active {
    background: $middle-grey;
    color: $empty-white;
  }

  .tabs__input:checked + & {
    @include active;
    border-color: $middle-grey;

    @include sm {
      display: flex;
      background: $empty-white;
      color: $cold-black;
      border: 1px solid $cold-black25;
      order: -1;

      .tabs__arrow {
        display: block;
      }

      &:hover {
        @include active;

        .tabs__arrow {
          svg path {
            fill: $empty-white;
          }
        }
      }
    }
  }

  @include xxl {
    padding: 40px 32px;
  }
  @include xl {
    padding: 32px;
  }
  @include lg {
    padding: 24px 32px;
  }
  @include md {
    padding: 24px;
  }
  @include sm {
    padding: 24px 16px;
    width: 100%;
    border: none;
    display: none;
    transition: background ease-in-out 0.2s, color ease-in-out 0.2s;

    &:hover {
      @include active;
    }
  }
}

.tabs_open {
  .tabs__label {
    @include sm {
      display: flex;
    }
  }
}
