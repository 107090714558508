.media-kit__heading {
  @extend .typography_h2;
  margin-bottom: 56px;

  @include md {
    margin-bottom: 48px;
  }
  @include sm {
    margin-bottom: 32px;
  }
}
