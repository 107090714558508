.tabs__text {
  @extend .typography_content;
  margin-bottom: 24px;

  .tab__title {
    display: block;
    padding-bottom: 12px;
  }

  .sub-title-text {
    display: flex;
    padding-bottom: 4px;
  }

  .platform-icon {
    max-height: 15px;
    margin-right: 4px;

    &.second-icon {
      margin-left: 4px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
