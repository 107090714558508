.accordion__arrow {
  width: 32px;
  height: 32px;
  margin-left: 16px;
  margin-top: 12px;

  svg {
    transition: transform ease-in-out 0.3s;

    path {
      transition: fill ease-in-out 0.3s;
    }
  }

  @mixin sm-md {
    height: 24px;
    width: 24px;
  }
  @include md {
    @include sm-md;
  }
  @include sm {
    @include sm-md;
  }
}
