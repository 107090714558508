.footer__nav-group {
  @include sm {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 48px;
  }
  @media (max-width: 460px) {
    width: 100%;
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
