.section {
  // content-visibility: auto;

  padding-bottom: 96px;

  @include md {
    padding-bottom: 72px;
  }
  @include sm {
    padding-bottom: 48px;
  }

  &_about {
    padding-bottom: 48px;

    @include md {
      padding-bottom: 40px;
    }
    @include sm {
      padding-bottom: 32px;
    }
  }
}
