.contact__textarea {
  @include contact__default-input;
  width: 100%;
  resize: none;
  height: 184px;

  @include md {
    height: 154px;
  }
  @include sm {
    height: 166px;
    padding-top: 48px;
  }
}
