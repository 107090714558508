.industries__arrow {
  display: none;
  height: 24px;
  width: 24px;
  cursor: pointer;

  svg {
    transition: transform ease-in-out 0.3s;
    path {
      transition: fill ease-in-out 0.3s;
    }
  }

  @include sm {
    display: block;
    margin-top: -12px;
  }

  &_open {
    svg {
      transform: scaleY(-1);
      path {
        fill: $purple-amethyst;
      }
    }
  }
}
