.features__item {
  display: flex;
  align-items: center;
  background: $empty-white;
  padding: 24px 32px;
  margin-right: 24px;
  min-width: 832px;

  scroll-snap-align: center; /* latest (Chrome 69+) */
  scroll-snap-coordinate: 50% 0%; /* older (Firefox/IE) */
  -webkit-scroll-snap-coordinate: 50% 0%; /* older (Safari) */

  // * Adding scrollable space before first item
  &:first-child {
    margin-left: 40px;

    @include md {
      margin-left: 32px;
    }
    @include sm {
      margin-left: 24px;
    }
  }

  // * Adding scrollable space after last item
  &:last-child {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: -40px;
      width: 40px;
      height: 1px;

      @include md {
        right: -32px;
        width: 32px;
      }
      @include sm {
        right: -24px;
        width: 24px;
      }
    }
  }

  @include lg {
    min-width: 636px;
  }
  @include md {
    min-width: 474px;
    margin-right: 16px;
  }
  @include sm {
    min-width: 230px;
    padding: 16px;
    margin-right: 8px;
  }
}
