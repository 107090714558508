.features__icon {
  width: 64px;
  height: 64px;
  margin-right: 32px;

  @include md {
    margin-right: 24px;
  }
  @include sm {
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }
}
