.tabs__column {
  @include xxl {
    width: calc(50% - 32px);
  }
  @include xl {
    width: calc(50% - 32px);
  }
  @include lg {
    width: calc(50% - 20px);
  }
  @include md {
    width: calc(50% - 12px);
  }
  @include sm {
    width: 100%;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
