.footer__nav {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @mixin xl-xxl {
    align-items: center;
  }
  @include xxl {
    @include xl-xxl;
  }
  @include xl {
    @include xl-xxl;
  }
  @include md {
    align-items: unset;
  }
  @media (max-width: 460px) {
    flex-direction: column;
    align-items: unset;
  }
}
