.footer__wrapper {
  display: flex;
  margin-bottom: 32px;

  @mixin sm-990-768 {
    flex-direction: column;
  }
  @media (max-width: 990px) and (min-width: 768px) {
    @include sm-990-768;
  }
  @include sm {
    @include sm-990-768;
  }
}
