.tabs {
  display: flex;
  flex-wrap: wrap;

  &_open {
    background: $bg10;

    .tabs__arrow svg {
      transform: scaleY(-1);

      path {
        fill: $purple-amethyst;
      }
    }
  }
}
