.accordion__heading {
  background: $empty-white;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  transition: background ease-in-out 0.3s;
  cursor: pointer;

  &_open {
    background: $bg10;

    .accordion__arrow svg {
      transform: scaleY(-1);

      path {
        fill: $purple-amethyst;
      }
    }
  }

  @include sm {
    padding: 24px;
  }
}
